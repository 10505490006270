body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  nav {
    background-color: #333;
    color: white;
    padding: 10px;
    flex: 0 1 auto;
  }
  
  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
  }
  
  nav ul li {
    margin: 0;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
  }
  
  nav ul li a:hover {
    background-color: #555;
  }
  
  .content {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 20px;
  }
  
  .home, .about, .services, .testimonials, .contact {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  p, ul {
    color: #555;
    font-size: 1em;
  }
  
  ul {
    padding-left: 20px;
  }
  
  .contact p {
    margin: 5px 0;
  }
  
  .contact p:first-of-type {
    margin-top: 10px;
  }
  