.App {
    text-align: center;
  }
  
  .App-logo {
    height: 20vmin;
    pointer-events: none;

  }
  
  .App-header {
    background-color: white; /* Change this to the desired header background color */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: black; /* Update text color */
    box-sizing: border-box;
  }
  
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white; /* Background color for both logo and nav bar */
    width: 100%;
    /* padding: 10px 20px; */
    box-sizing: border-box;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo-name {
    margin-left: -11%;
    font-size: 1.2em;
    color: #800000; /* Color for the company name */
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .nav-bar {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .nav-bar li {
    margin: 0 15px;
  }
  
  .nav-bar a {
    color: #800000;
    text-decoration: none;
    font-weight: bold;
  }
  
  .nav-bar a:hover {
    text-decoration: underline;
  }
  
  .main-image {
    height: 530px;
    width: 95%
  }

  .work-image {
    height: 490px;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  
  .image-text {
    position: absolute;
    width: 20%;
    top: 90%;
    left: 16%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  .work-text {
    position: absolute;
    width: 40%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2em;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

  .about-image {
    height: 450px;
    width: 100%
  }
  
  .about-text {
    margin-top: -22%;
    position: relative;
    width: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    background-color: white;
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 10px
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); */
  }

  main {
    padding: 20px;
  }
  
  .about-section{
    height:470px;
    background: #fff;
    /* padding: 20px 20px 0px 20px; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* margin: 20px 0; */
    text-align: left;
    padding-top: 55px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .services-section, .contact-section, .technologies-section {
    background: #fff;
    /* padding: 20px 20px 0px 20px; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    text-align: left;
    padding-bottom: 10px;
  }
  
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  p, ul {
    color: #555;
    font-size: 26px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  .contact-section p {
    margin: 5px 0;
  }
  
  .contact-section p:first-of-type {
    margin-top: 10px;
  }

  .website-image{
    width: 200px;
    height:160px;
  }
  
  
  @media (max-width: 600px) {
    .App {
      text-align: center;
    }
    
    .App-logo {
      height: 9vmin;
      pointer-events: none;
  
    }
    
    .App-header {
      background-color: white; /* Change this to the desired header background color */
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      font-size: calc(10px + 2vmin);
      color: black; /* Update text color */
      box-sizing: border-box;
    }
    
    .nav-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white; /* Background color for both logo and nav bar */
      width: 100%;
      /* padding: 10px 20px; */
      box-sizing: border-box;
    }
    
    .logo-container {
      display: flex;
      align-items: center;
    }
    
    .logo-name {
      margin-left: -8%;
      font-size: 9px;
      color: #800000; /* Color for the company name */
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }
    
    .nav-bar {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      margin-left: -20px;
      margin-top: -12px;
    }
    
    .nav-bar li {
      margin: 0 5px;
    }
    
    .nav-bar a {
      color: #800000;
      text-decoration: none;
      font-weight: bold;
    }
    
    .nav-bar a:hover {
      text-decoration: underline;
    }
    
    .main-image {
      height: 175px;
      width: 95%
    }
  
    .work-image {
      height: 490px;
      width: 95%;
      margin-left: 2.5%;
      margin-top: 2%;
      margin-bottom: 2%;
    }
    
    .image-text {
      position: absolute;
      width: 20%;
      top: 22%;
      left: 16%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 8px;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    }
  
    .work-text {
      position: absolute;
      width: 40%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 2em;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    }
  
    .about-image {
      height: 220px;
      width: 100%
    }
    
    .about-text {
      margin-top: -37%;
      position: relative;
      width: 50%;
      left: 30%;
      transform: translate(-50%, -50%);
      background-color: white;
      font-size: 12px;
      font-weight: bold;
      border-radius: 10px
      /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); */
    }
  
    main {
      padding: 20px;
    }
    
    .about-section{
      height: 240px;
      background: #fff;
      /* padding: 20px 20px 0px 20px; */
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      /* margin: 20px 0; */
      text-align: left;
      padding-top: 55px;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .services-section, .contact-section, .technologies-section {
      background: #fff;
      /* padding: 20px 20px 0px 20px; */
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin: 20px 0;
      text-align: left;
      padding-bottom: 10px;
    }
    
    h2 {
      color: #333;
      margin-bottom: 10px;
    }
    
    p, ul {
      color: #555;
      font-size: 26px;
    }
    
    ul {
      padding-left: 20px;
    }
    
    .contact-section p {
      margin: 5px 0;
    }
    
    .contact-section p:first-of-type {
      margin-top: 10px;
    }
  
    .website-image{
      width: 100px;
      height:80px;
    }
  }